import BaseController from './base_controller';
import I18n from 'i18n-js';

export default class extends BaseController {
  static targets = ['fileInput', 'files', 'inputContainer', 'examineButton', 'inputText', 'fileBox', 'destroyButton', 'modal', 'inputAccept', 'inputMaxFileSize']
  static values = { size: String, totalSize: Number }

  connect() {
    this.multiple = this.inputContainerTarget.getAttribute('multiple') == 'true'
    this.documentAttribute = this.inputContainerTarget.getAttribute('document')
    this.objectAttribute = this.inputContainerTarget.getAttribute('object')
    this.nameAttribute = this.inputContainerTarget.getAttribute('name')
  }

  selectFile() {
    this.fileInputTarget.click();
  }

  fileInputId(startAt) {
    if (this.multiple) {
      return `${this.objectAttribute}_${startAt}_${this.documentAttribute}`
    } else {
      return this.nameAttribute ? `${this.nameAttribute}_${this.documentAttribute}` : `${this.objectAttribute}_${this.documentAttribute}`;
    }
  }

  fileInputName(startAt) {
    if (this.multiple) {
      return this.nameAttribute ? `${this.nameAttribute}[${startAt}][${this.documentAttribute}]` : `${this.objectAttribute}[${startAt}][${this.documentAttribute}]`;
    } else {
      return `${this.objectAttribute}[${this.documentAttribute}]`
    }
  }

  async updateFileInfo() {
    const files = this.fileInputTarget.files
    if (files.length > 0) {
      const file = files[0]
      const validFileSize = this.validFileSize(file)
      const validFileExtension = await this.validFileExtension(file)
      const valid = validFileSize.valid && validFileExtension.valid
      const message = [validFileExtension.message, validFileSize.message].filter(Boolean).join(', ')

      if (valid) {
        this.appendFile(file)
        this.removeWarning()
      } else {
        this.removeFile()
        this.addWarning(message)
      }
    }
  }

  removeFile() {
    const dataTransfer = new DataTransfer()
    const input = this.fileInputTarget
    input.files = dataTransfer.files
    input.setAttribute('value', 'false')
    input.dispatchEvent(new Event('change'))
  }

  getMagicNumber = (file, callback) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      const arr = new Uint8Array(reader.result).subarray(0, 12)
      const header = Array.from(arr).map(byte => byte.toString(16).padStart(2, '0')).join('')
      callback(header)
    }
    reader.readAsArrayBuffer(file.slice(0, 12))
  }

  validFileExtension(file) {
    const MAGIC_NUMBERS = {
      jpg: ['ffd8ff'],
      jpeg: ['ffd8ff'],
      png: ['89504e470d0a1a0a']
    }

    const acceptedExtensions = this.fileInputTarget.getAttribute('accept')

    if (!acceptedExtensions) {
      return Promise.resolve({ valid: true, message: '' })
    }

    const allowedExtensions = acceptedExtensions
      .split(',')
      .map(ext => ext.trim().replace(/^\./, '').toLowerCase())

    const fileName = file.name.toLowerCase()
    const fileExtension = fileName.includes('.') ? fileName.split('.').pop() : ''

    if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
      return Promise.resolve({
        valid: false,
        message: I18n.t('components.file_input.message.file_format_not_allowed')
      })
    }

    const hasMagicNumberValidation = MAGIC_NUMBERS.hasOwnProperty(fileExtension)

    if (!hasMagicNumberValidation) {
      return Promise.resolve({
        valid: true,
        message: ''
      })
    }

    return new Promise((resolve) => {
      this.getMagicNumber(file, (fileMagicNumber) => {
        const expectedMagicNumbers = MAGIC_NUMBERS[fileExtension]
        const isHeaderValid = expectedMagicNumbers.some(expectedMagicNumber =>
          fileMagicNumber.startsWith(expectedMagicNumber)
        )

        if (!isHeaderValid) {
          this.inputAcceptTarget.classList.add('invalid')
          resolve({
            valid: false,
            message: I18n.t('components.file_input.message.file_corrupted_or_invalid')
          })
        } else {
          resolve({ valid: true, message: '' })
        }
      })
    })
  }

  validFileSize(file) {
    if (this.hasInputMaxFileSizeTarget) {
      let maximumFileSize = this.fileInputTarget.getAttribute('max-file-size')
      if (maximumFileSize) {
        const valid = file.size + this.totalSizeValue <= maximumFileSize
        const message = valid ? '' : I18n.t('components.file_input.message.file_size_exceeded')
        if (!valid) this.inputMaxFileSizeTarget.classList.add('invalid')
        return { valid: valid, message: message }
      } else {
        return { valid: true, message: '' }
      }
    } else {
      return { valid: true, message: '' }
    }
  }

  appendFile(file) {
    let startAt = parseInt(this.inputContainerTarget.getAttribute('start-at'))
    const newFileInput = this.createFileInput(startAt, file)
    let fileElement = document.createElement('div')
    let fileNameElement = document.createElement('div')
    let fileIconElement = this.createFileIcon(startAt, file.size)
    fileElement.classList.add('file')
    fileElement.setAttribute('data-file-index', startAt)
    fileNameElement.classList.add('file-name')
    fileNameElement.innerHTML = file.name
    fileElement.appendChild(fileNameElement)
    fileElement.appendChild(fileIconElement)
    fileElement.appendChild(newFileInput)
    fileElement.setAttribute('id', `file-${startAt}`)
    this.filesTarget.append(fileElement)
    this.inputContainerTarget.setAttribute('start-at', startAt + 1)
    this.filesTarget.classList.add('active')
    let maximumFileAmount = this.fileInputTarget.getAttribute('max-file-amount')
    let currentFileCount = this.filesTarget.children.length
    this.totalSizeValue += file.size
    if (!this.multiple) {
      this.disableExamineButton()
      if (this.sizeValue == 'sm') {
        this.inputContainerTarget.style.display = 'none'
      }
    }
    else if (currentFileCount == maximumFileAmount) {
      this.disableExamineButton()
    }
  }

  createFileInput(startAt, file) {
    const newFileInput = document.createElement('input')
    newFileInput.type = 'file'
    const newFileList = new DataTransfer();
    newFileList.items.add(file)
    newFileInput.files = newFileList.files
    newFileInput.style.display = 'none'
    newFileInput.name = this.fileInputName(startAt)
    newFileInput.id = this.fileInputId(startAt)

    return newFileInput
  }

  createFileIcon(startAt, filesize = 0) {
    const id = `${this.fileInputId(startAt)}_delete_button`
    let fileIconElement = document.createElement('i')
    fileIconElement.classList.add('delete-icon', 'fa', 'fa-times')
    fileIconElement.setAttribute('data-action', 'click->file-input#deleteFile')
    fileIconElement.setAttribute('data-filesize', filesize)
    fileIconElement.setAttribute('file-target', `file-${startAt}`)
    fileIconElement.setAttribute('id', id)

    return fileIconElement
  }

  deleteFile(event) {
    let element = event.currentTarget
    let id = element.getAttribute('file-target')
    let filesize = element.getAttribute('data-filesize')
    this.totalSizeValue -= filesize
    this.filesTarget.querySelector(`#${id}`).remove()
    this.removeFile()
    let maximumFileAmount = this.fileInputTarget.getAttribute('max-file-amount')
    let currentFileCount = this.filesTarget.children.length
    if (!this.multiple) {
      this.enableExamineButton(false)
      if (this.sizeValue == 'sm') {
        this.inputContainerTarget.removeAttribute('style')
      }
    }
    else if (currentFileCount < maximumFileAmount){
      this.enableExamineButton(true)
    }
  }

  startDeleting(event) {
    event.preventDefault()
    let button = event.currentTarget
    let modal = this.modalTarget
    $(modal).modal('hide')
    let url = ''
    let fileBox = this.getFileBoxPill(button.dataset.fileId)
    url = fileBox.getAttribute('url')
    this.toggleAvailability(fileBox)
    fetch(url)
      .then(response => response.json())
      .then(data => this.handleResponse(data))
  }

  getFileBoxPill(fileBoxId) {
    let fileBox = document.createElement('div')
    let boxId = fileBoxId
    if (this.multiple) {
      fileBox = this.filesTarget.querySelector(`#file-box-${boxId}`)
    } else {
      fileBox = this.fileBoxTarget
    }

    return fileBox
  }

  toggleAvailability(fileBox) {
    let loadButton = fileBox.querySelector('#iconBox')
    let disabled = loadButton.disabled
    loadButton.disabled = !disabled
    loadButton.classList.toggle('disabled')
    let fileNameBox = fileBox.querySelector('.file-name')
    fileNameBox.classList.toggle('disabled')
    let icon = fileBox.querySelector('.fa-solid')
    if (disabled) {
      icon.classList.add('fa-times')
      icon.classList.remove('fa-circle-notch', 'fa-spin')
    } else {
      icon.classList.remove('fa-times')
      icon.classList.add('fa-circle-notch', 'fa-spin')
    }
  }

  handleResponse(data) {
    let fileBox = this.getFileBoxPill(data.file_id)
    if (data.success) {
      if (this.multiple) {
        fileBox.remove()
      } else {
        this.fileBoxTarget.remove()
        this.enableExamineButton(false)
        this.removeFile()
      }
      this.turboMessage('success', I18n.t('components.file_input.message.file_deleted_successfully'))
    } else {
      this.toggleAvailability(fileBox)
      this.turboMessage('alert', I18n.t('components.file_input.message.failure_deleting_file'))
    }
  }

  disableExamineButton() {
    this.examineButtonTarget.removeAttribute('data-action')
    this.examineButtonTarget.classList.add('disabled')
    this.inputTextTarget.setAttribute('disabled', 'disabled')
    this.inputTextTarget.classList.add('disabled')
    this.inputTextTarget.value = ''
    if (!this.multiple){
      this.inputTextTarget.setAttribute('placeholder', I18n.t('components.file_input.message.file_loaded'))
    }
    else {
      this.inputTextTarget.setAttribute('placeholder', I18n.t('components.file_input.message.files_loaded'))
    }
  }

  enableExamineButton(multiple) {
    this.examineButtonTarget.setAttribute('data-action', 'click->file-input#selectFile')
    this.examineButtonTarget.classList.remove('disabled')
    this.inputTextTarget.removeAttribute('disabled')
    this.inputTextTarget.classList.remove('disabled')
    this.inputTextTarget.removeAttribute('placeholder')
    if (!multiple){
      this.filesTarget.textContent = ''
      this.filesTarget.classList.remove('active')
    }
  }

  showDeleteModal(event) {
    if (this.multiple) {
      this.destroyButtonTarget.setAttribute('data-file-id', event.currentTarget.dataset.fileId)
    }
    $(this.modalTarget).modal()
  }

  addWarning(message) {
    const input = this.inputTextTarget
    input.classList.add('invalid')
    input.setAttribute('disabled', 'disabled')
    const finalMessage = message.charAt(0).toUpperCase() + message.slice(1).toLowerCase()
    input.setAttribute('placeholder', finalMessage)
    this.examineButtonTarget.classList.add('invalid')
  }

  removeWarning() {
    this.inputTextTarget.classList.remove('invalid')
    this.examineButtonTarget.classList.remove('invalid')
    if (this.hasInputAcceptTarget) this.inputAcceptTarget.classList.remove('invalid')
    if (this.hasInputMaxFileSizeTarget) this.inputMaxFileSizeTarget.classList.remove('invalid')
    this.inputTextTarget.removeAttribute('placeholder')
  }
}
